$(document).ready(function() {

  var url = window.location.href
  if (url == 'http://192.168.1.33/gatesystems/app/index.php' || url == 'http://192.168.1.33/gatesystems/app/' || url == 'http://192.168.1.33/gatesystems/app/index.php?ps=main' || url == 'http://localhost/gatesystems/app/') {
    $('.content').addClass('main').parent('.col-lg-9').css('width','100%')
  }
  if ($('.content').hasClass('main')) {
    $('.catalog').addClass('horizontal')
    $('.content_title').first().css({'margin-top':'5px'}).end().find('a').removeClass('black')
  }
  if ($('#bxslider')) {
    $("<style type='text/css'> @media screen and (min-width:1200px){ .col-lg-5 {  width: 34%;  padding: 0 15px 0 0 !important; } .col-lg-7 { width: 65.33%; padding: 0 0 0 15px !important } }@media screen and (min-width:1600px){ .col-lg-5 {  width: 28%;  padding: 0 15px 0 0 !important; } .col-lg-7 { width: 71.33%; } } </style>").appendTo("head");
  };

  $('.btn-red').each(function() {
    if ($('.price').length) {
      $(this).parent().prev().find('a.prev_next').css({
        paddingTop: '0',
        display: 'block'
      });
    } else {
      $(this).parent().prev().css({
        display: 'inline-block'
      });
      $('a.prev_next').css({
        paddingTop: '8px',
        display: 'block'
      });
    }
  });



  ///// Back to top

  $(window).scroll(function() {
    if ($(this).scrollTop() > 330) {
      $('#back-to-top').fadeIn();
    } else {
      $('#back-to-top').fadeOut();
    }
  });
  // scroll body to 0px on click
  $('#back-to-top').click(function() {
    $('#back-to-top').tooltip('hide');
    $('body,html').animate({
      scrollTop: 0
    }, 100);
    return false;
  });

  $('#back-to-top').tooltip('show');

  ///// Carousel

  $(".carousel").carousel({
    interval: false
  });

  $('.carousel-inner .item').first().addClass('active');

  $('#myCarouselPhone .carousel-inner .item').first().addClass('active');
  $('#myCarouselRetina .carousel-inner .item').first().addClass('active');

  $('#myCarousel').carousel({
    interval: false
  });

  $('#myCarouselPhone').carousel({
    interval: false
  });

  $('#myCarouselRetina').carousel({
    interval: false
  });

  $('.carousel-control').each(function() {
    if ($('.caption').length) {
      $(this).css('top', '-40px');
    } else {
      $(this).css('top', '0');
    }
  });

  // handles the carousel thumbnails
  $('[id^=carousel-selector-]').click(function() {
    var id_selector = $(this).attr("id");
    var id = id_selector.substr(id_selector.length - 1);
    id = parseInt(id);
    $('#myCarousel').carousel(id);
    $('[id^=carousel-selector-]').removeClass('selected');
    $(this).addClass('selected');
  });

  // when the carousel slides, auto update
  $('#myCarousel').on('slid', function(e) {
    var id = $('.item.active').data('slide-number');
    id = parseInt(id);
    $('[id^=carousel-selector-]').removeClass('selected');
    $('[id=carousel-selector-' + id + ']').addClass('selected');
  });



  /* -------------------- Fancybox -------------------- */

  $('.fancybox').fancybox({
    helpers: {
      overlay: {
        locked: false
      }
     }
  });

  /* -------------------- ./Fancybox -------------------- */



  /* -------------------- BxSlider -------------------- */
  var slidenumber,
    width = $(window).width();

  if (width <= 365) {
    slidenumber = 3;
  } else if (width > 365) {
    slidenumber = 4;
  }

  if (width >= 1200) {
    slidenumber = 3;
  }

  var realSlider = $("ul#bxslider").bxSlider({
    speed: 1000,
    pager: false,
    nextText: '',
    prevText: '',
    infiniteLoop: false,
    controls: false,
    onSlideBefore: function($slideElement, oldIndex, newIndex) {
      changeRealThumb(realThumbSlider, newIndex);

    }

  });

  var realThumbSlider = $("ul#bxslider-pager").bxSlider({
    minSlides: 3,
    maxSlides: slidenumber,
    slideWidth: 74,
    slideMargin: 0,
    moveSlides: 1,
    pager: false,
    speed: 1000,
    infiniteLoop: false,
    controls: true,
    nextText: '<span></span>',
    prevText: '<span></span>',
    onSlideBefore: function($slideElement, oldIndex, newIndex) {
      /*$("#sliderThumbReal ul .active").removeClass("active");
      $slideElement.addClass("active"); */
    }


  });

  linkRealSliders(realSlider, realThumbSlider);

  if ($("#bxslider-pager li").length < 5) {
    $("#bxslider-pager .bx-next").hide();
  }

  // sincronizza sliders realizzazioni
  function linkRealSliders(bigS, thumbS) {

    $("ul#bxslider-pager").on("click", "a", function(event) {
      event.preventDefault();
      var newIndex = $(this).parent().attr("data-slideIndex");
      bigS.goToSlide(newIndex);
    });
  }

  //slider!=$thumbSlider. slider is the realslider
  function changeRealThumb(slider, newIndex) {

    var $thumbS = $("#bxslider-pager");
    $thumbS.find('.active').removeClass("active");
    $thumbS.find('li[data-slideIndex="' + newIndex + '"]').addClass("active");

    if (slider.getSlideCount() - newIndex >= 4) slider.goToSlide(newIndex);
    else slider.goToSlide(slider.getSlideCount() - 4);
  }
  /* -------------------- ./BxSlider -------------------- */


  /* -------------------- Form styler -------------------- */
  (function($) {
    $.fn.selectBlockFunc = function(options) {

      return this.each(function() {
        var select = this;
        var select_vp = $('.select_vp', select);
        var select_vp_el = $('span', select_vp);
        var select_inp = $('.select_inp', select);
        var select_val = select_inp.attr('value');

        if (select_val == '') {
          select_val = "0";
        }

        var el_first_text = $('span[rel="' + select_val + '"]', select_vp);
        var first_text = el_first_text.html();
        var select_fild = $('span.select_fild', select);

        select_fild.html(first_text);


        $('.select_body', select).click(function() {
          if (!select_vp.hasClass("open")) {
            openSelect();

          } else {

            closeSelect();
          }
        });

        select_vp_el.click(function() {
          var select_vp_el_text = $(this).html();
          var select_vp_el_rel = $(this).attr("rel");

          select_fild.html(select_vp_el_text);
          select_inp.val(select_vp_el_rel);
          closeSelect();
        });

        function openSelect() {
          $("div.select_vp.open").each(function() {
            $(this).animate({
              height: "hide"
            }, 200).removeClass("open");
          });

          select_vp.animate({
            height: "show"
          }, 200);
          select_vp.addClass("open");
          select_vp.css("width", "100%");
          $(select).addClass("active");
        }

        function closeSelect() {
          select_vp.animate({
            height: "hide"
          }, 200);
          select_vp.removeClass("open");
          $(select).removeClass("active");
        }


        $(window).resize(function() {
          selectVpScrolWidth();
        });
        selectVpScrolWidth();

        function selectVpScrolWidth() {
          var select_vp_width = $(select).width() - 2; // console.log('select_vp_width = ' + select_vp_width);
          var scroll_width = select_vp_width + 15; // console.log(scroll_width);
          select_vp.css("width", select_vp_width);
          $('> div', select_vp).css("width", scroll_width);

        }
      });
    };
  })(jQuery);

  $(document).click(function(event) {

    if ($(event.target).closest($('.select_block')).length) {} else {

      $("div.select_vp").animate({
        height: "hide"
      }, 200).removeClass("open");
      $('.select_block').removeClass("active");
      event.stopPropagation();
    }

  });
  /* /selectBlockFunc */
  $(document).ready(function() {
    $(".select_block").selectBlockFunc();
  });




  /* checkbox & radio */
  $(document).ready(function() {

    $("div.check-box").each(function(i) {
      if ($('input[type=checkbox]', this).attr('checked') == 'checked') {
        $(this).addClass('checked');
      }
    });

    $("div.check-box").click(function() {
      if (!$(this).hasClass('checked')) {

        $(this).addClass('checked');
        $(this).children('input[type=checkbox]').attr('checked', 'checked');

      } else {

        $(this).removeClass('checked');
        $(this).children('input[type=checkbox]').removeAttr('checked');
      }
    });

    checkedRadioButton();

    function checkedRadioButton() {
      $("input:radio:not(:checked)").parent('.radio-button').removeClass('checked');
      $("input:radio:checked").parent('.radio-button').addClass('checked');
    }

    $("div.radio-button > div").click(function() {
      $(this).next('input').click();
      checkedRadioButton();
    });

  });


  /* -------------------- Add to copy -------------------- */
  jQuery.fn.addtocopy = function(usercopytxt) {
    var options = {
      htmlcopytxt: '<br>More: <a href="' + window.location.href + '">' + window.location.href + '</a><br>',
      minlen: 25,
      addcopyfirst: false
    }
    $.extend(options, usercopytxt);
    var copy_sp = document.createElement('span');
    copy_sp.id = 'ctrlcopy';
    copy_sp.innerHTML = options.htmlcopytxt;
    return this.each(function() {
      $(this).mousedown(function() {
        $('#ctrlcopy').remove();
      });
      $(this).mouseup(function() {
        if (window.getSelection) { //good times
          var slcted = window.getSelection();
          var seltxt = slcted.toString();
          if (!seltxt || seltxt.length < options.minlen) return;
          var nslct = slcted.getRangeAt(0);
          seltxt = nslct.cloneRange();
          seltxt.collapse(options.addcopyfirst);
          seltxt.insertNode(copy_sp);
          if (!options.addcopyfirst) nslct.setEndAfter(copy_sp);
          slcted.removeAllRanges();
          slcted.addRange(nslct);
        } else if (document.selection) { //bad times
          var slcted = document.selection;
          var nslct = slcted.createRange();
          var seltxt = nslct.text;
          if (!seltxt || seltxt.length < options.minlen) return;
          seltxt = nslct.duplicate();
          seltxt.collapse(options.addcopyfirst);
          seltxt.pasteHTML(copy_sp.outerHTML);
          if (!options.addcopyfirst) {
            nslct.setEndPoint("EndToEnd", seltxt);
            nslct.select();
          }
        }
      });
    });
  }

  // $(document).ready(function() {
  //   $("body:not(input[type=text]):not(textarea)").addtocopy({
  //     htmlcopytxt: '<br>Подробнее: <a href="' + window.location.href + '">' + window.location.href + '</a>'
  //   });
  // });
  /* -------------------- ./Add to copy -------------------- */



})